<template>
  <v-app>
    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!--default-settings /-->
  </v-app>
</template>

<script>
  export default {
    name: 'DefaultLayout',

    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultFooter: () => import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
      // DefaultSettings: () => import(
      /* webpackChunkName: "default-settings" */
      //   './Settings'
      // ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },
  }
</script>

<style>
@media print
{
  #default-drawer {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
  }
  .non-printable {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
  }
  .printable {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: visible !important;
  }
}
</style>
